
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, { LANGUAGE_KEY, USER_ID } from '@/services/StorageService'
import { DataEmitterService } from '@/services/DataEmiterService'

export default {

  components: {},
  data() {
    return {
      selectedIds: new Set(),
      reverseSelect: false,
      search: '',
      items: [],
      page: 1,
      totalPages: 0,
      totalElements: 0,
      selectedCategory: 'ProductsCustomization',
      categories: [ 'All' ],
      itemsPerPage: 10,
      filter: 'Winners',
      filterOptions: [
        // {
        //   label: 'All',
        //   value: 'All',
        // },
        {
          label: 'Winners',
          value: 'Winners',
        },
        {
          label: 'Non winners',
          value: 'NonWinners',
        },
        {
          label: 'Post contest winners',
          value: 'PostContestWinners',
        },
      ]
    }
  },

  computed: {
    pages() {
      return [
        this.page - 2,
        this.page - 1,
        this.page,
        this.page + 1,
        this.page + 2,
      ].filter( page => page >= 1 && page <= this.totalPages )
        .sort( ( a, b ) => Math.abs( a - this.page ) - Math.abs( b - this.page ) )
        .slice( 0, 3 )
        .sort( ( a, b ) => a - b )
    },
  },

  watch: {},
  beforeRouteLeave( to, from, next ) {
    next()
  },

  async mounted() {
    await this.getCategories()
    await this.updateItems()
  },

  methods: {
    updateFilter( filter ) {
      this.filter = filter
      this.updateItems()
    },

    updateSearchText( text ) {
      this.search = text
      this.updateItems()
    },

    updateItemsPerPage( value ) {
      this.itemsPerPage = value
      this.updateItems()
    },

    updatePage( page ) {
      this.page = page
      this.updateItems()
    },

    select( id = null ) {
      if ( id === null ) {
        this.selectedIds.clear()
        this.reverseSelect = !this.reverseSelect
        return
      }

      if ( this.selectedIds.has( id ) ) {
        this.selectedIds.delete( id )
      } else {
        this.selectedIds.add( id )
      }

      this.selectedIds = new Set( this.selectedIds );
    },

    async updateItems() {
      try {
        let url = `api/admin-user-competition/winner?sortBy=id&direction=DESC&count=${ this.itemsPerPage }&page=${ this.page }`

        if ( this.search ) {
          url += `&searchText=${ encodeURIComponent( this.search ) }`
        }

        if ( this.filter ) {
          url += `&applicationStatus=${ encodeURIComponent( this.filter ) }`
        }

        if ( this.selectedCategory ) {
          url += `&area=${ encodeURIComponent( this.selectedCategory ) }`
        }

        const data = await ApiRequestService.getRequest( url )
        console.log( 'data', data )
        this.items = data?.data ?? []
        this.totalElements = data?.totalElements ?? 0
        this.totalPages = data?.totalPages ?? 0
        this.page = data?.currentPage ?? 1

        if ( this.page > this.totalPages ) {
          this.page = this.totalPages

          if ( this.page ) {
            await this.updateItems()
          }
        }

      } finally {
        DataEmitterService.$emit( 'showLoader', false );
      }
    },
    async updateCategory( category ) {
      this.selectedCategory = category;
      await this.updateItems();
    },

    async getCategories() {
      let url = `api/admin-user-competition/area`;
      const categories = await ApiRequestService.getRequest( url );
      if ( categories && categories?.length ) {
        this.categories = this.categories.concat( categories );
      }
      this.selectedCategory = this.categories[0];
    },

    async sendMail( sendAll = false ) {
      DataEmitterService.$emit( 'showLoader', true );
      console.log( sendAll )
      console.log( this.selectedIds.values() );


      let url = `api/admin-user-competition/winner-mail?sortBy=id&direction=DESC&count=${ this.itemsPerPage }&page=${ this.page }`

      if ( this.search ) {
        url += `&searchText=${ encodeURIComponent( this.search ) }`
      }

      if ( this.filter ) {
        url += `&applicationStatus=${ encodeURIComponent( this.filter ) }`
      }

      if ( this.selectedCategory ) {
        url += `&area=${ encodeURIComponent( this.selectedCategory ) }`
      }
      const hasSent = await ApiRequestService.postRequest( url, {
        isSendAll: !!sendAll,
        applicationIds: [ ...this.selectedIds.values() ]
      } );

      DataEmitterService.$emit( 'showLoader', false );
      if(!!hasSent.success){
        await this.updateItems();
        DataEmitterService.$emit( 'showSuccess', true );
      }
    }
  },
}

